<template>
  <div class="viewer-container py-3">
    <alert v-if="!intake || !report" class="mb-0">Nothing to view...</alert>
    <div ref="report">
      <div v-if="report && !intake.reportHtml">
        <div class="section-box border rounded mb-5" v-if="clientInfo.name">
          <h2 class="title border-bottom py-4 rounded-top px-2">
            Client Information
          </h2>
          <div class="content px-3 pb-2">
            <div class="item d-flex">
              <div class="key">ID:</div>
              <div class="value">{{ clientInfo["patient_id"] }}</div>
            </div>
            <div class="item d-flex">
              <div class="key">Name:</div>
              <div class="value">{{ clientInfo["name"] }}</div>
            </div>
            <div class="item d-flex">
              <div class="key">Gender:</div>
              <div class="value">{{ clientInfo["sex"] }}</div>
            </div>
            <div class="item d-flex">
              <div class="key">Date Of Birth:</div>
              <div class="value">{{ clientInfo["dob"] }}</div>
            </div>
            <div class="item d-flex">
              <div class="key">Age:</div>
              <div class="value">{{ clientInfo["age"] }}</div>
            </div>
            <div class="item d-flex" v-if="clientInfo['diagnosis']">
              <div class="key">Diagnosis:</div>
              <div class="value">{{ clientInfo["diagnosis"] }}</div>
            </div>
            <div class="item d-flex" v-if="clientInfo['assessment']">
              <div class="key">Assessment:</div>
              <div class="value">{{ clientInfo["assessment"] }}</div>
            </div>
            <div class="item d-flex" v-if="clientInfo['treatment']">
              <div class="key">Treatment:</div>
              <div class="value">{{ clientInfo["treatment"] }}</div>
            </div>
            <div class="item d-flex" v-if="clientInfo['intervention']">
              <div class="key">Intervention:</div>
              <div class="value">{{ clientInfo["intervention"] }}</div>
            </div>
          </div>
        </div>
        <tree-node :node="report"></tree-node>
      </div>
      <div v-if="report && intake.reportHtml">
        <div class="content px-3 pb-2" v-html="report"></div>
      </div>
    </div>
    <div ref="summary" class="report-summary" v-if="reportSummary">
      <div class="text-right copy-container">
        <button
          class="btn btn-sm btn-link p-0 text-black"
          @click="copyText('narrative')"
        >
          Copy <i class="fa-regular fa-copy"></i>
        </button>
      </div>
      <div v-html="reportSummary"></div>
    </div>
    <div ref="soap" class="report-summary soap-summary" v-if="soapSummary">
      <div class="text-right">
        <button
          class="btn btn-sm btn-link p-0 text-black"
          @click="copyText('soap')"
        >
          Copy <i class="fa-regular fa-copy"></i>
        </button>
      </div>
      <div v-html="soapSummary"></div>
    </div>
    <div
      class="text-center pt-4 d-flex flex-wrap justify-content-center"
      v-if="report"
    >
      <template v-if="!intake.reportHtml">
        <button
          class="btn btn-secondary mr-md-4 mb-2 mb-md-0 col-10 col-md-auto"
          v-if="!reportSummary"
          @click="
            generateSummary(
              ['4499', '4500', '4501'].includes(`${intake.type}`)
                ? 'testingResults'
                : 'narrative'
            )
          "
          :disabled="narrativeLoading"
        >
          <span
            v-if="narrativeLoading"
            class="fa fa-spin fa-circle-notch mr-2"
          ></span>
          {{
            ["4499", "4500", "4501"].includes(`${intake.type}`)
              ? "Summarize Test Results"
              : "Generate Narrative Summary"
          }}
        </button>
        <button
          class="btn btn-secondary mr-md-4 mb-2 mb-md-0 col-10 col-md-auto"
          v-if="reportSummary"
          @click="copyText('narrative')"
        >
          <span
            v-if="narrativeLoading"
            class="fa fa-spin fa-circle-notch mr-2"
          ></span>
          Copy
          {{
            ["4499", "4500", "4501"].includes(`${intake.type}`)
              ? "Testing Results"
              : "Narrative Summary"
          }}
          to Clipboard
        </button>
        <button
          class="btn btn-secondary mr-md-4 mb-2 mb-md-0 col-10 col-md-auto"
          v-if="!soapSummary"
          @click="generateSummary('soap')"
          :disabled="soapLoading"
        >
          <span
            v-if="soapLoading"
            class="fa fa-spin fa-circle-notch mr-2"
          ></span>
          Generate SOAP Note Format
        </button>
        <button
          class="btn btn-secondary mr-md-4 mb-2 mb-md-0 col-10 col-md-auto"
          v-if="soapSummary"
          @click="copyText('soap')"
        >
          <span
            v-if="soapLoading"
            class="fa fa-spin fa-circle-notch mr-2"
          ></span>
          Copy SOAP Note to Clipboard
        </button>
      </template>
      <button
        class="btn btn-black col-10 col-md-auto"
        @click="copyText('report')"
      >
        Copy Report to Clipboard
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import TreeNode from "./TreeNode.vue";
import Swal from "sweetalert2";

export default {
  name: "IntakeViewer",
  components: {
    TreeNode,
  },
  props: ["intake"],
  data() {
    return {
      reportSummary: null,
      soapSummary: null,
      narrativeLoading: false,
      soapLoading: false,
    };
  },
  computed: {
    report: function () {
      if (this.intake?.reportHtml) {
        return this.intake.reportHtml;
      }
      const originReport =
        JSON.parse(JSON.stringify(this.intake?.report)) || {};
      const keysToRemove = [
        "name",
        "Name",
        "Patient ID",
        "PatientID",
        "patient_id",
        "Sex",
        "sex",
        "DOB",
        "dob",
        "Age",
        "age",
        "Diagnosis",
        "diagnosis",
        "Assessment",
        "assessment",
        "Treatment",
        "treatment",
        "Intervention",
        "intervention",
      ];

      keysToRemove.forEach((key) => {
        delete originReport[key];
      });
      return originReport;
    },
    clientInfo: function () {
      const report = this.intake?.report || {};
      if (report.name || report.Name) {
        return {
          name: report.name || report.Name,
          patient_id:
            report["Patient ID"] || report["PatientID"] || report["patient_id"],
          sex: report["Sex"] || report["sex"],
          dob: report["DOB"] || report["dob"],
          age: report["Age"] || report["age"],
          diagnosis: report["Diagnosis"] || report["diagnosis"],
          assessment: report["Assessment"] || report["assessment"],
          treatment: report["Treatment"] || report["treatment"],
          intervention: report["Intervention"] || report["intervention"],
        };
      }
      return {};
    },
  },
  methods: {
    ...mapActions({
      generateReportSummary: "intakeForms/generateSummary",
    }),
    copyText(type) {
      let textToCopy = this.$refs.report.innerText;
      if (type == "narrative") {
        textToCopy = this.$refs.summary.innerText;
      } else if (type == "soap") {
        textToCopy = this.$refs.soap.innerText;
      }
      navigator.clipboard.writeText(textToCopy).then(
        function () {
          Swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            icon: "success",
            title: `The report ${
              type !== "report" ? "summary " : ""
            }has been copied to your clipboard.`,
          });
        },
        function (err) {
          console.error("Async: Could not copy report: ", err);
        }
      );
    },
    generateSummary(type) {
      if (type == "narrative" || type == "testingResults") {
        this.narrativeLoading = true;
      } else if (type == "soap") {
        this.soapLoading = true;
      }
      this.generateReportSummary({ id: this.intake.id, type }).then(
        (summary) => {
          const result = (summary || "")
            .replace("```html", "")
            .replace("```", "");
          if (type == "narrative" || type == "testingResults") {
            this.reportSummary = result;
            this.narrativeLoading = false;
          } else if (type == "soap") {
            this.soapSummary = result;
            this.soapLoading = false;
          }
        }
      );
    },
  },
};
</script>
<style lang="scss">
.viewer-container {
  .section-box {
    .title {
      background-color: rgba(37, 37, 37, 0.4);
      color: #ffffff;
    }
    .content {
      .item {
        margin-bottom: 4px;
      }
      .key {
        font-weight: bold;
        margin-right: 12px;
        text-transform: capitalize;
      }
    }
  }
  .report-summary {
    border-top: 6px solid #c8c8c861;
    border-radius: 8px;
    margin-top: 12px;
    padding: 4px 12px 12px 12px;
    background-color: #c8c8c861;
    h2,
    h3 {
      text-decoration: underline;
      margin-top: 12px;
    }
    p {
      margin-bottom: 0;
    }
  }
}
</style>
