<template>
  <div>
    <div
      v-if="level !== 1 || isObject(node)"
      :class="`level${level}${
        isObject(node) && [1, 3].includes(level)
          ? ' section-box border rounded'
          : ' child'
      }`"
    >
      <!-- Iterate over the object keys and values -->
      <div
        v-for="(value, key) in node"
        :key="key"
        :class="`content${level == 1 ? '' : ' px-3'}`"
      >
        <!-- If the value is an object, recursively render it -->
        <div
          v-if="isObject(value)"
          :class="`section-box mb-3${level == 3 ? 'border rounded' : ''}`"
        >
          <h2
            class="title border-bottom py-4 rounded-top px-2"
            v-if="level == 1"
          >
            {{ formatKey(key) }}
          </h2>
          <h3
            class="sub-title mb-3 text-decoration-underline"
            v-if="level == 2"
          >
            {{ formatKey(key) }}:
          </h3>
          <h3 class="sub-title mb-3" v-if="level > 2">{{ formatKey(key) }}:</h3>
          <tree-node :node="value" :level="level + 1"></tree-node>
        </div>
        <!-- If the value is an array, render it as a list -->
        <div v-else-if="isArray(value)" class="item">
          <div class="key">{{ formatKey(key) }}:</div>
          <div class="value">
            <ul class="list mt-2 mb-3">
              <li v-for="(item, index) in value" :key="index">{{ item }}</li>
            </ul>
          </div>
        </div>
        <!-- Render key-value pairs directly -->
        <div v-else>
          <div :class="`item d-flex${level == 1 ? ' pl-2' : ''}`">
            <div class="key">{{ formatKey(key) }}:</div>
            <div class="value">{{ value }}</div>
          </div>
        </div>
      </div>
    </div>
    <pre v-if="level == 1 && typeof node == 'string'">
      {{ node }}
    </pre>
  </div>
</template>

<script>
export default {
  name: "TreeNode",
  props: {
    node: {
      // JSON object or string to render
      type: [Object, String],
      required: true,
    },
    level: {
      // Track the nesting level
      type: Number,
      default: 1,
    },
  },
  methods: {
    isObject(value) {
      // Check if a value is an object (not null and not an array)
      return (
        typeof value === "object" && value !== null && !Array.isArray(value)
      );
    },
    isArray(value) {
      // Check if the value is an array
      return Array.isArray(value);
    },
    formatKey(key) {
      if (!key) {
        return "";
      }
      // Format the key, for example by inserting spaces before capital letters
      return `${key}`
        .replace(/([A-Z])/g, " $1")
        .replace(/^./, (str) => str.toUpperCase());
    },
  },
};
</script>
